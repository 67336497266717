export default {
  name: 'CountrySelector',
  props: {
    countryCode: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    }
  },
  methods: {
    showCountrySelector () {
      this.$store.dispatch('ui/showCountrySelector')
      this.$analytics.selectContent('menu', 'countrySelector', 'openCountrySelector', undefined)
    }
  }
}
